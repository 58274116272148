import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

static targets = ["checkbox", "linkBtn"];

  updateLink() {
    const treatmentIds = this.checkboxTargets
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);

    const url = new URL(this.linkBtnTarget.href);
    url.searchParams.set('treatment_ids', treatmentIds);
    this.linkBtnTarget.href = url.toString();
  }

  switchTab(){
    const treatmentPlansTab = document.querySelector("a[href='#" + this.element.dataset.treatmentPlanTab + "']");
    if (treatmentPlansTab) {
      treatmentPlansTab.click();
    }
  }
}