import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "resultStatus", "datepickerAppointmentAt",
    "appointmentTrackingType", "pmsData",
    "placeholder", "appointmentList",
    "scriptId", "scriptFrame", 'submitBtn',
    "situationType", "followUPType", "followUPContainer",
    "voicemailContainer", "spokeMadeApptContainer",
    "spokeNoApptContainer", "otherNoApptContainer", "notes"
  ]

  connect() {
    ToggleCheckbox.initialize();
    datetime_picker.time_input();

    if (this.hasSituationTypeTarget) {
      this.handleSituationType()
      $(this.situationTypeTarget).find('input[type="radio"]').on('change', () => {
        this.handleSituationType()
      })
    }

    if (this.hasFollowUPTypeTarget) {
      this.followUPTypeTargets.forEach(_target => {
        this.handleFollowUPType(_target);

        $(_target).find('input[type="radio"]').on('change', () => {
          this.handleFollowUPType(_target);
        });
      });
    }


    $(this.resultStatusTarget).find('input[type="radio"]').on('change', () => {
      var resultStatusValue = $(this.resultStatusTarget).find('input[type="radio"]:checked').val()

      this.handleTargetProp(this.voicemailContainerTarget, resultStatusValue === "voicemail")
      this.handleTargetProp(this.spokeNoApptContainerTarget, resultStatusValue === "spoke_no_appt")
      this.handleTargetProp(this.otherNoApptContainerTarget, resultStatusValue === "other_no_appt")

      this.allowSubmitBtn(true);
      this.handleSpokeMadeAppt(resultStatusValue)
      this.handleNotes()
      this.handleFollowUPContainer()
    });

    $(this.appointmentListTarget).on('change', () => {
      this.allowSubmitBtn(true);
      const id = this.appointmentListTarget.querySelector('input:checked').value;
      if ($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('selected_future_appointment').length > 0) {
        $($(this.resultStatusTarget).closest('form')[0]).append('<input type="hidden" name="event" class="event" value="scheduled_future_appointment" />');
        $($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('selected_future_appointment')[0]).val(id);
        $($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('patient_decision_type')[0]).val("scheduled");
      }
    });
  }

  handleFollowUPContainer() {
    const resultStatusValue = $(this.resultStatusTarget).find('input[type="radio"]:checked').val();
    const followUPContainerTarget = this.followUPContainerTarget;
    const enabled = resultStatusValue === "voicemail" || resultStatusValue === "spoke_no_appt"

    this.handleTargetProp(followUPContainerTarget, enabled)
    followUPContainerTarget.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  handleNotes() {
    const resultStatusValue = $(this.resultStatusTarget).find('input[type="radio"]:checked').val();
    const notesTarget = this.notesTarget;

    if (resultStatusValue === "voicemail" || resultStatusValue === "spoke_no_appt") {
      $(notesTarget).removeClass('d-none');
      $(notesTarget).prop('required', resultStatusValue === "spoke_no_appt");
    } else {
      $(notesTarget).addClass('d-none');
      $(notesTarget).prop('required', false);
    }
  }

  allowSubmitBtn = (enabled) => {
    const submitBtn = this.submitBtnTarget
    submitBtn.disabled = !enabled;
    submitBtn.classList.toggle("disabled", !enabled);
  }

  handleTargetProp = (target, enabled) => {
    if (enabled) {
      $(target).removeClass('d-none');
      $('textarea:not(.not_required)', target).prop('required', true);
      $(target).find('input:not([type="checkbox"])').prop('required', true);
      $(target.getElementsByTagName('select')).prop('required', true);
      $(target.getElementsByTagName('textarea')).prop('disabled', false)
      $(target.getElementsByTagName('input')).prop('disabled', false)
      $(target.getElementsByTagName('select')).prop('disabled', false)
    } else {
      $(target).addClass('d-none');
      $('textarea:not(.not_required)', target).prop('required', false);
      $(target).find('input:not([type="checkbox"])').prop('required', false);
      $(target.getElementsByTagName('select')).prop('required', false);
      $(target.getElementsByTagName('textarea')).prop('disabled', true)
      $(target.getElementsByTagName('input')).prop('disabled', true)
      $(target.getElementsByTagName('select')).prop('disabled', true)
    }
  }

  handleSpokeMadeAppt = (selectedValue) => {
    if (selectedValue === "spoke_made_appt") {
      $(this.spokeMadeApptContainerTarget).removeClass('d-none');
      $(this.appointmentTrackingTypeTarget.getElementsByTagName('input')).prop('disabled', false)
      this.handleAppointmentTracking()
    } else {
      $(this.spokeMadeApptContainerTarget).addClass('d-none');
      $(this.appointmentListTarget.getElementsByTagName('input')).prop('disabled', true);
      $(this.appointmentTrackingTypeTarget.getElementsByTagName('input')).prop('disabled', true)
      $(this.placeholderTarget.getElementsByTagName('select')).prop('disabled', true)
      $(this.placeholderTarget.getElementsByTagName('select')).prop('required', false)
      $(this.appointmentTrackingTypeTarget.getElementsByTagName('input')).prop('required', false)

      this.handleTxpAppointmentFields(false)
    }
  }

  handleAppointmentTracking = () => {
    if (!$(this.appointmentTrackingTypeTarget).find('input[type="radio"]').is(":checked")) {
      $(this.appointmentTrackingTypeTarget).find('input[type="radio"]:first').prop("checked", true)
    }

    let appointmentType = $(this.appointmentTrackingTypeTarget).find('input[type="radio"]:checked').val()
    switch (appointmentType) {
      case 'pms_data':
        this.handleFutureAppointmentList()
        break;
      case 'placeholder':
        this.handleEstimatedAppointment()
        break;
    }
  }

  handleFutureAppointmentList = () => {
    $(this.pmsDataTarget).removeClass('d-none');
    this.handleTargetProp(this.placeholderTarget, false)
    this.handleTxpAppointmentFields(true)

    if (this.appointmentListTarget.querySelectorAll('input:checked').length < 1) {
      this.allowSubmitBtn(false);
    }
  }

  handleEstimatedAppointment = () => {
    $(this.pmsDataTarget).addClass('d-none');
    this.handleTargetProp(this.placeholderTarget, true)
    this.handleTxpAppointmentFields(false)

    if ($(this.datepickerAppointmentAtTarget).find('input').val()) {
      this.allowSubmitBtn(true);
    } else {
      this.allowSubmitBtn(false);
    }

    var that = this
    $(that.datepickerAppointmentAtTarget).find('input').flatpickr({
      onChange: function (selectedDates, dateStr, instance) {
        if ($(that.resultStatusTarget).find('input[type="radio"]:checked').val() != "spoke_made_appt" || $(that.appointmentTrackingTypeTarget).find('input[type="radio"]:checked').val() != 'placeholder') return;

        if (selectedDates.length > 0) {
          that.allowSubmitBtn(true);
        } else {
          that.allowSubmitBtn(false);
        }
      }
    });
  }

  handleTxpAppointmentFields = (enabled) => {
    if ($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('selected_future_appointment').length <= 0) return;

    $($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('selected_future_appointment')[0]).prop('disabled', !enabled);
    $($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('event')[0]).prop('disabled', !enabled);
    $($(this.resultStatusTarget).closest('form')[0].getElementsByClassName('patient_decision_type')[0]).prop('disabled', !enabled);
  }

  handleSituationType = () => {
    if (!this.situationTypeTarget) return;

    const communication_script_id = $(this.situationTypeTarget).find('input[type="radio"]:checked').data('communication-script-id')
    this.scriptIdTarget.value = communication_script_id
    const $frame = $(this.scriptFrameTarget)
    $frame.attr('src', $frame.attr('src').replace(/\/\d+\//, `/${communication_script_id}/`));
  }

  handleFollowUPType = (target) => {
    const followUPTypeValue = $(target).find('input[type="radio"]:checked').val()
    if (!followUPTypeValue) return;

    target.querySelectorAll('input[type="radio"]').forEach(element => {
      if (element.value == followUPTypeValue) {
        $(`.${element.id}_container`).removeClass('d-none');
      } else {
        $(`.${element.id}_container`).addClass('d-none');
      }
    });
  }
}
