import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "bonus", "earnedAmount", "employee", "earnedRewardTemplate", 
    "container", "empId", "tenantStripeSetupStatus"
  ];

  connect() {
    // Check if Stripe setup status is available
    if (this.hasTenantStripeSetupStatusTarget) {
      const isTenantStripeConnected = this.tenantStripeSetupStatusTarget.value === 'true';
      this.updateOptions(isTenantStripeConnected, "Stripe account is not connected. Please connect to send rewards.");

      // Load appropriate employee options based on Stripe status
      if (isTenantStripeConnected) {
        if (this.hasEmpIdTarget) {
          this.checkEmployeeStripeStatus(this.empIdTarget.value);
        }
        if (this.hasEmployeeTarget) {
          $(this.employeeTarget).select2().on('change', this.loadPaymentOptions.bind(this));
        }
      } else if (this.hasEmployeeTarget) {
        $(this.employeeTarget).select2().on('change', this.loadMetrics.bind(this));
      }
    }

    this.loadMetrics();  // Load metrics initially

    $('.task-assignees').on('change', this.loadMetrics)
  }

  disconnect() {
    // Clean up any event listeners here (like `select2` change listener)
    if (this.hasEmployeeTarget) {
      $(this.employeeTarget).select2('destroy');  // Proper cleanup of `select2`
    }
  }

  setEarnedAmount(e = null) {
    if (this.hasEarnedAmountTarget && this.hasBonusTarget) {
      this.earnedAmountTarget.value = this.bonusTarget.value;
      this.loadMetrics();  // Recalculate metrics after setting the earned amount
    }
  }

  loadPaymentOptions(e) {
    if (!e || !e.target) {
      console.error('Invalid event in loadPaymentOptions');
      return;
    }
    this.checkEmployeeStripeStatus(e.target.value);  // Check Stripe status when employee changes
  }

  checkEmployeeStripeStatus(id) {
    $.ajax({
      url: `/employees/${id}`,
      method: 'GET',
      dataType: 'json',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
      },
      success: (data) => {
        const isStripeConnected = data.stripe_account_id !== null;
        this.updateOptions(isStripeConnected);
      },
      error: (xhr, status, error) => {
        console.error('Error fetching employee Stripe status:', error);
      }
    });
  }

  updateOptions(isStripeConnected, errorMessage = 'Employee does not have a connected Stripe account. Please ask them to connect their account to receive a bonus through stripe.') {
    if (!isStripeConnected) {
      let content = this.earnedRewardTemplateTarget.innerHTML;

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;

      // Disable Stripe-related elements
      const stripeElement = tempDiv.querySelector('#stripe');
      const stripeDescriptionElement = tempDiv.querySelector('#stripeDescription');

      if (stripeElement) {
        stripeElement.style.pointerEvents = 'none';
        stripeElement.style.opacity = '0.5';
      }

      if (stripeDescriptionElement) {
        const stripeErrorElement = document.createElement('p');
        stripeErrorElement.classList.add('font-italic', 'text-danger');
        stripeErrorElement.innerHTML = errorMessage;
        stripeDescriptionElement.appendChild(stripeErrorElement);
      }

      content = tempDiv.innerHTML;
      this.containerTarget.innerHTML = content;
    }

    this.setEarnedAmount();  // Update the earned amount based on the status
  }

  loadMetrics() {
    // Load metrics template and populate with calculated values
    const metricsTemplate = document.querySelector('#achievement-reward-log-metrics-template');
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = metricsTemplate.innerHTML;

    // Elements to update with calculated metrics
    const totalStaffMemberMetric = tempDiv.querySelector('#achievement-log-form-staff-members-count');
    const totalAmountMetric = tempDiv.querySelector('#achievement-log-form-staff-total-amount');
    const totalManualMetric = tempDiv.querySelector('#achievement-log-form-staff-manual-count');
    const payLaterMetric = tempDiv.querySelector('#achievement-log-form-staff-paylater-count');
    const stripeMetric = tempDiv.querySelector('#achievement-log-form-staff-stripe-count');

    // Calculate total earned amount and employee count
    const earnedAmountTotal = [...document.querySelectorAll('.earned-amount-input')]
      .reduce((total, input) => total + parseFloat(input.value || 0), 0);
    const totalEmployees = [...document.querySelectorAll('.employee-input')]
      .filter(input => input.value.trim() !== '').length;

    // Calculate payment option metrics
    const checkedPaymentOptionsArray = [...document.querySelectorAll('.task-assignees')].filter(input => input.checked);

    let totalManual = 0, totalPayLater = 0, totalStripe = 0;
    checkedPaymentOptionsArray.forEach(input => {
      if (input.value === 'manual') totalManual += 1;
      if (input.value === 'pay_later') totalPayLater += 1;
      if (input.value === 'stripe') totalStripe += 1;
    });

    // Update metrics
    if (totalManualMetric) totalManualMetric.textContent = totalManual;
    if (payLaterMetric) payLaterMetric.textContent = totalPayLater;
    if (stripeMetric) stripeMetric.textContent = totalStripe;

    if (totalAmountMetric) {
      totalAmountMetric.textContent = earnedAmountTotal.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    }

    if (totalStaffMemberMetric) totalStaffMemberMetric.textContent = totalEmployees;

    // Insert the populated metrics view into the DOM
    const metricsView = document.querySelector('#achievement-reward-log-metrics-view');
    metricsView.innerHTML = tempDiv.innerHTML;
  }
}
