import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $(this.element).find("select:not(.no-select2)").select2();
    HygAftercareLog.initialize();
    TreatmentPlan.patient_decision();
    ServiceProposal.presentation_type();
    ServiceProposal.client_decision();
    AchievementRewardLog.approval_type();
    TreatmentPlan.set_future_appointment();
    TreatmentPlan.treatment_plan_type();
    TreatmentPlan.presentation_form_type();
    TreatmentPlan.patient_decision_type();
    TreatmentPlan.presentation_type();
    LabCase.date_tracker_type();
    SecretShopperCall.init();
    $("#training_chapter_training_series_category_list, #tag_list").select2({
      tags: true,
      insertTag: function (data, tag) {
        // Insert the tag at the end of the results
        data.push(tag);
      }
    }); //for TrainingSerise form

    $("#treatment_plan_appointments_list").change(function () {
      $('#treatment_plan_id_radio_button').val($('#treatment_plan_appointments_list :input:checked').val())
    }); // Need an separate stimulus controler for treatment_plan_appointment
    TreatmentPlan.case_acceptance_leaderboard();
  }
}
