( ->
  class AchievementRewardLog

    handle_approved_fields = () ->
      $('#approval-type-id').val("approve")
      $('#approved_fields :input').prop('disabled', false)
      $("#approved_fields, #approved_footer").removeClass('d-none')
      $('#denied_fields :input').prop('disabled', true)
      $("#denied_fields, #denied_footer").addClass('d-none')

    handle_denied_fields = () ->
      $('#approval-type-id').val("deny")
      $('#denied_fields :input').prop('disabled', false)
      $('#denied_fields :input:not(#treatment_plan_denied_notes)').prop('required', true)
      $("#denied_fields, #denied_footer").removeClass('d-none')
      $('#approved_fields :input').prop('disabled', true)
      $("#approved_fields, #approved_footer").addClass('d-none')

    approval_type: ->
      if $('#approval-type-id').val() == "approve"
        handle_approved_fields()
      else if $('#approval-type-id').val() == "deny"
        handle_denied_fields()

      $('#approval-type-id').change ->
        if $('#approval-type-id').val() == "approve"
          handle_approved_fields()
        else if $('#approval-type-id').val() == "deny"
          handle_denied_fields()

      $('#state_radio_group').change ->
        if $("#achievement_reward_log_state_approve").prop("checked")
          handle_approved_fields()
        else if $("#achievement_reward_log_state_deny").prop("checked")
          handle_denied_fields()

  window.AchievementRewardLog = new AchievementRewardLog();
).call this