import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["totalInput", "patientInput", "insuranceInput"]

  connect() {
    let patientInputField = $(this.patientInputTarget.getElementsByTagName('input'))
    let totalInputField = $(this.totalInputTarget.getElementsByTagName('input'))

    this.calculateInsuranceField = this.calculateInsuranceField.bind(this);
    patientInputField.on("keyup change", this.calculateInsuranceField)

    this.resetFields = this.resetFields.bind(this);
    totalInputField.on("keyup change", this.resetFields)

    patientInputField.on("keyup change", (event) => {
      var patientValue = converter.convertCurrencyToFloat($(event.target).val());
      var totalValue = converter.convertCurrencyToFloat(totalInputField.val());

      if (patientValue > totalValue) {
        let field = $(event.target)
        field.val(totalValue)
        field.trigger("change");
        field.trigger("keyup");
        alert("Patient value cannot be greater than total value");
      }
    });
  }

  toggleInsuranceField(e) {
    const checkboxValue = e.currentTarget.checked
    const insuranceInput = this.insuranceInputTarget.getElementsByTagName('input')
    this.insuranceInputTarget.classList.toggle('d-none', checkboxValue);
    if (checkboxValue)
      $(insuranceInput).val(0)
  }

  calculateInsuranceField() {
    if ($(this.insuranceInputTarget).hasClass('d-none')) return;

    const insuranceInput = this.insuranceInputTarget.getElementsByTagName('input')[0]
    const totalInput = this.totalInputTarget.getElementsByTagName('input')[0]
    const patientInput = this.patientInputTarget.getElementsByTagName('input')[0]
    insuranceInput.value = converter.convertCurrencyToFloat(totalInput.value) - converter.convertCurrencyToFloat(patientInput.value)
  }

  resetFields() {
    this.insuranceInputTarget.getElementsByTagName('input')[0].value = 0
    this.patientInputTarget.getElementsByTagName('input')[0].value = this.totalInputTarget.getElementsByTagName('input')[0].value

    $(this.patientInputTarget).trigger("change");
    $(this.patientInputTarget).trigger("keyup");
  }
}
