import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["icon", "title", "styling", 'description'];

  connect() {
    this.updateUI();
  }

  update(event) {
    const selectedInputType = event.target;
    this.updateButton(selectedInputType);
  }

  updateUI() {
    const selectedRadio = this.element.querySelector('input[type="radio"]:checked');
    if (selectedRadio) {
      this.updateButton(selectedRadio);
    }
  }

  updateButton(selectedInputType) {
    this.iconTarget.className = `icon ${selectedInputType.dataset.fontIcon}`;
    this.titleTarget.innerHTML = selectedInputType.dataset.title;
    this.descriptionTarget.innerHTML = selectedInputType.dataset.description;
    this.stylingTarget.style.backgroundColor = selectedInputType.dataset.bgColor;
    this.stylingTarget.style.color = selectedInputType.dataset.textColor;
  }
}
