// app/javascript/controllers/copy_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    targetSelector: String, // Target selector for the text to copy
    msgtype: { type: String, default: "notice" }, // Type of success message (notice, alert, info)
    message: { type: String, default: "Text copied to clipboard!" }, // Success message
    errorMessage: { type: String, default: "Failed to copy text. Please try again." }, // Error message
  };

  copy(event) {
    event.preventDefault();

    const targetElement = this.findTargetElement();
    if (targetElement) {
      const textToCopy = targetElement.innerText || targetElement.textContent;

      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          this.showToastr("success", this.messageValue); // Show success notification
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          this.showToastr("error", this.errorMessageValue); // Show error notification
        });
    } else {
      console.error("Target element not found for copying text.");
      this.showToastr("error", "Target element not found."); // Show error if no target
    }
  }

  findTargetElement() {
    if (this.targetSelectorValue) {
      return document.querySelector(this.targetSelectorValue);
    }
    return null;
  }

  showToastr(type, message) {
    // Configure Toastr options
    toastr.options.closeButton = false;
    toastr.options.progressBar = true;
    toastr.options.positionClass = "toast-top-left";
    toastr.options.timeOut = 1500;

    // Show the appropriate notification
    if (type === "success") {
      toastr.success(message);
    } else if (type === "error") {
      toastr.error(message);
    } else {
      toastr.info(message);
    }
  }
}
